import React from 'react'
import {
  Card,
  CardHeader,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Box,
  CardBody,
  Flex,
  CardFooter,
  Button, ButtonGroup, Badge, useBreakpointValue
} from '@chakra-ui/react'
import { FaHome } from 'react-icons/fa'
import { MdEmail, MdSettings } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { committees as committeeData } from '../../../constants/testData'

const CommitteeList = (): JSX.Element => {
  const navigate = useNavigate()

  const isAdmin = false
  const membersCanMessage = true

  const committeesMemberSort = committeeData.sort((a, b) => { return (a.isCommitteeMember === b.isCommitteeMember) ? 0 : a.isCommitteeMember ? -1 : 1 })
  const committees = committeesMemberSort.sort((a, b) => { return (a.isCommitteeAdmin === b.isCommitteeAdmin) ? 0 : a.isCommitteeAdmin ? -1 : 1 })

  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt='72px'>
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Stack spacing='4' direction={{ base: 'column', lg: 'row' }} justify='space-between'>
            <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight='medium'>
              Committee Directory
            </Heading>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='6'>
              <>
                {committees.map((committee) => (
                  <Card minH='36' key={committee.id}>
                    <CardHeader>
                      <Flex gap='4'>
                        <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                          <Box>
                            <Heading size='sm'>{committee.name}</Heading>
                            <Stack direction='row' spacing='2'>
                              {committee.isProgram ? <Badge alignSelf='center' colorScheme='blue' variant='solid'>PROGRAM</Badge> : <></>}
                              {committee.isCommitteeAdmin
                                ? <Badge alignSelf='center' colorScheme='red' variant='solid'>ADMIN</Badge>
                                : committee.isCommitteeMember
                                  ? <Badge alignSelf='center' colorScheme='green' variant='solid'>MEMBER</Badge>
                                  : <></>}
                            </Stack>
                          </Box>
                        </Flex>
                      </Flex>
                    </CardHeader>

                    <CardBody>
                      <Stack spacing={6}>
                        <Stack direction='row' spacing={1}>
                          <Text fontWeight='bold'>Committee Chair:</Text>
                          <Text>{committee.chair}</Text>
                        </Stack>
                        <Text>{committee.description}</Text>
                      </Stack>
                    </CardBody>

                    <CardFooter>
                      <Stack flex='1' direction={isMobile ? 'column' : 'row'} justify='space-between'>
                        <Button flex={isMobile ? '' : '1'} colorScheme='blue' variant='solid' leftIcon={<FaHome />} onClick={() => { navigate(committee.homePage) }}>
                          Home page
                        </Button>
                        {isAdmin || committee.isCommitteeAdmin || committee.isCommitteeMember || membersCanMessage
                          ? <Button flex={isMobile ? '' : '1'} colorScheme='orange' variant='solid' leftIcon={<MdEmail />} onClick={() => { navigate(committee.messageingPage) }}>
                            Send Message
                          </Button> // eslint-disable-line
                          : <></>}
                        {isAdmin || committee.isCommitteeAdmin
                          ? <Button flex={isMobile ? '' : '1'} colorScheme='red' variant='solid' leftIcon={<MdSettings />} onClick={() => { navigate(committee.managePage) }}>
                            Manage
                          </Button> // eslint-disable-line
                          : <></>}
                      </Stack>
                    </CardFooter>
                  </Card>
                ))}
              </>
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default CommitteeList
