import React from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'

interface PageHeaderProps {
  name: string
  description: string
}
const PageHeader = ({ name, description }: PageHeaderProps): JSX.Element => {
  return (
    <Stack spacing='1' p='2'>
      <Heading size={{ base: 'xs', md: 'sm' }} fontWeight='medium'>
        {name}
      </Heading>
      <Text color='muted'>
        {description}
      </Text>
    </Stack>
  )
}

export default PageHeader
