import React, {useEffect, useRef, useState} from 'react';
import {
    CardBody,
    Container,
    Card,
    useBreakpointValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Text,
    Stack, Icon, Skeleton, ButtonGroup
} from "@chakra-ui/react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {EventClickArg} from "@fullcalendar/core";
import {FaRegClock, FaAlignJustify, FaExternalLinkAlt } from "react-icons/fa"
import { MdLocationOn } from "react-icons/md"

interface calendarEvents {
    title: string
    start: string
    end: string
    color: string
    extendedProps: {
        location: string
        sourceCalendar: {
            name: string
            id: string
        }
        gid: string
        description: string
    }
}

function Calendar() {
    const calendarRef = useRef<FullCalendar>(null)
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [eventTitle, setEventTitle] = useState<string>('')
    const [eventStartDateTime, setEventStartDateTime] = useState<Date>(new Date())
    const [eventAllDay, setEventAllDay] = useState<Boolean>(false)
    const [eventEndDateTime, setEventEndDateTime] = useState<Date>(new Date())
    const [eventLocation, setEventLocation] = useState<string | null>(null)
    const [eventDescription, setEventDescription] = useState<string | null>(null)
    const [googleCalendarId, setGoogleCalendarId] = useState<string | null>(null)

    const [events, setEvents] = useState<calendarEvents[]>([
        {
            title: 'June General Meeting',
            start: '2024-07-11T19:00-05:00',
            end: '2024-07-11T21:00-05:00',
            color: '#FF0000',
            extendedProps: {
                location: '123 Some St, city, TX 12345',
                gid: 'aXFva2xxaG0zZmJ0c3AxaW1iNzQ5YWI1OGcgY185OTQzMWMwYjAwZGU4MDYxMjUxYTNlMmEyZDcxMGJmZmZmYjMyODdmY2ZhNThmZjc0ZTJmODVjMDgyNWU3YzhmQGc',
                sourceCalendar: {
                    name: 'Board Meetings',
                    id: 'board@group.google.com'
                },
                description: 'June General Meeting'
            }
        },
        {
            title: 'June Board Meeting',
            start: '2024-07-04T19:00-05:00',
            end: '2024-07-04T20:00-05:00',
            color: '#338d8d',
            extendedProps: {
                location: '123 Some St, city, TX 12345',
                gid: '1',
                sourceCalendar: {
                    name: 'General Meetings',
                    id: 'general@group.google.com'
                },
                description: 'June Board Meeting\n\n\nTest\nTst\nsdg\nzasdf'
            }
        },
        {
            title: 'COP Event',
            start: '2024-07-20T13:00-05:00',
            end: '2024-07-20T20:00-05:00',
            color: '#FF00FF',
            extendedProps: {
                location: '123 Some St, city, TX 12345',
                gid: '1',
                sourceCalendar: {
                    name: 'COP Events',
                    id: 'cop@group.google.com'
                },
                description: 'COP Patrol'
            }
        },
        {
            title: 'Child ID Event',
            start: '2024-07-07T17:00-05:00',
            end: '2024-07-07T20:00-05:00',
            color: '#FF6600',
            extendedProps: {
                location: '123 Some St, city, TX 12345',
                gid: '1',
                sourceCalendar: {
                    name: 'Child ID Events',
                    id: 'childid@group.google.com'
                },
                description: ''
            }
        },
    ])

    useEffect(() => {
        if (calendarRef.current === null) {
            return
        }
        const calendarApi = calendarRef.current.getApi()
        if (isMobile) {
            calendarApi.changeView(isMobile ? 'dayGridDay' : 'dayGridMonth')
            calendarApi.setOption('headerToolbar', {start: 'prev,next', center: 'title', end: ''})
        }
        calendarApi.updateSize()
    }, [isMobile]);

    function customBtnClick() {
        setEvents([...events, {
            title: 'COP Event',
            start: '2024-07-27T13:00-05:00',
            end: '2024-07-29T15:00-05:00',
            color: '#FF00FF',
            extendedProps: {
                location: '',
                gid: '1',
                sourceCalendar: {
                    name: 'COP Events',
                    id: 'cop@group.google.com'
                },
                description: 'COP extended event'
            }
        }])

        if (calendarRef.current === null) {
            return
        }
        const calendarApi = calendarRef.current.getApi()
        calendarApi.updateSize()
    }

    function eventClick(info: EventClickArg) {
        info.jsEvent.preventDefault()
        console.log(info)

        if (!info.event.title || !info.event.start || info.event.allDay) {
            return
        }

        setEventTitle(info.event.title)
        setEventStartDateTime(info.event.start)
        setEventAllDay(info.event.allDay)

        if (info.event.allDay || !info.event.end) {
            setEventEndDateTime(new Date(new Date(info.event.start).setHours(23, 59, 59, 999)))
        } else {
            setEventEndDateTime(info.event.end)
        }

        if (info.event.extendedProps && info.event.extendedProps.location) {
            setEventLocation(info.event.extendedProps.location)
        } else {
            setEventLocation(null)
        }

        if (info.event.extendedProps && info.event.extendedProps.description) {
            setEventDescription(info.event.extendedProps.description)
        } else {
            setEventDescription(null)
        }

        if (info.event.extendedProps && info.event.extendedProps.gid) {
            setGoogleCalendarId(info.event.extendedProps.gid)
        } else {
            setGoogleCalendarId(null)
        }

        onOpen()
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent my='2' ml='2' mr='4'>
                    <ModalHeader>
                        <Stack direction='row' align='center'>
                            <>{eventTitle}</>
                            <Icon as={FaExternalLinkAlt} cursor='pointer' onClick={() => window.open(`https://calendar.google.com/calendar/u/0/r/eventedit/${googleCalendarId}`, '_blank')} />
                        </Stack></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Stack>
                            { eventAllDay ?
                                <Stack direction='row' align='center'>
                                    <Icon as={FaRegClock} />
                                    <Text>
                                        {eventStartDateTime.toLocaleDateString('en-US', {weekday: 'long', month: 'long', day: 'numeric'})}
                                    </Text>
                                </Stack>

                                :

                                <Stack direction='row' align='center'>
                                    <Icon as={FaRegClock} />
                                    <Text>
                                        {eventStartDateTime.toLocaleDateString('en-US', {weekday: 'long', month: 'long', day: 'numeric'})}
                                    </Text>
                                    <Text>
                                        &#8226;
                                    </Text>
                                    <Text>
                                        {eventStartDateTime.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}
                                    </Text>
                                    <Text>
                                        -
                                    </Text>
                                    <Text>
                                        {eventEndDateTime.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}
                                    </Text>
                                </Stack>

                            }
                            { eventLocation ?
                                <Stack direction='row' align='center'>
                                    <Icon as={MdLocationOn} />
                                    <Text cursor='pointer' onClick={() => window.open('https://www.google.com/maps/place/' + eventLocation, '_blank')}>
                                        {eventLocation}
                                    </Text>
                                </Stack>
                                :
                                <></>
                            }
                            {

                            }

                            {eventDescription ?
                                <Stack direction='row' align='baseline'>
                                    <Icon as={FaAlignJustify} />
                                    <Text noOfLines={5} whiteSpace='pre-line'>
                                        {eventDescription}
                                    </Text>
                                </Stack>
                                :
                                <></>
                            }
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <ButtonGroup>
                            <Button colorScheme='orange'>Edit</Button>
                            <Button colorScheme='red'>Delete</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '72px'} h='100%'>
                <Card h='100%'>
                    <CardBody>
                        <FullCalendar
                            height={isMobile ? 'calc(100vh - 149px)' : 'calc(100vh - 164px)'}
                            ref={calendarRef}
                            plugins={[ dayGridPlugin ]}
                            initialView='dayGridMonth'
                            headerToolbar={{start: 'prev,next myCustomButton', center: 'title', end: 'dayGridMonth,dayGridWeek,dayGridDay'}}
                            events={events}
                            customButtons={{
                                myCustomButton: {
                                    text: 'custom!',
                                    click: customBtnClick
                                }
                            }}
                            eventClick={eventClick}
                            editable={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default Calendar;