export interface committeeDataProps {
  id: number
  name: string
  description: string
  chair: string
  chairMemberId: number
  numMembers: number
  homePage: string
  messageingPage: string
  managePage: string
  isProgram: boolean
  isCommitteeAdmin: boolean
  isCommitteeMember: boolean
}

export const committees: committeeDataProps[] = [
  {
    id: 1,
    name: 'Ashton\'s Room',
    description: 'Description for Ashton\'s Room Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 0,
    numMembers: 23,
    homePage: '/committees/1/home',
    messageingPage: '/committees/1/messaging',
    managePage: '/committees/1/admin',
    isProgram: true,
    isCommitteeAdmin: false,
    isCommitteeMember: true
  },
  {
    id: 2,
    name: 'Battle of the Badges',
    description: 'Description for Battle of the Badges Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 1,
    numMembers: 23,
    homePage: '/committees/2/home',
    messageingPage: '/committees/2/messaging',
    managePage: '/committees/2/admin',
    isProgram: false,
    isCommitteeAdmin: true,
    isCommitteeMember: false
  },
  {
    id: 3,
    name: 'Birthday Card',
    description: 'Description for the Birthday Card Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 3,
    numMembers: 23,
    homePage: '/committees/3/home',
    messageingPage: '/committees/3/messaging',
    managePage: '/committees/3/admin',
    isProgram: false,
    isCommitteeAdmin: false,
    isCommitteeMember: true
  },
  {
    id: 4,
    name: 'Child ID',
    description: 'Description for the Child ID Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 4,
    numMembers: 23,
    homePage: '/committees/4/home',
    messageingPage: '/committees/4/messaging',
    managePage: '/committees/4/admin',
    isProgram: false,
    isCommitteeAdmin: false,
    isCommitteeMember: true
  },
  {
    id: 5,
    name: 'Citizens On Patrol',
    description: 'Description for the Citizens On Patrol Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 5,
    numMembers: 23,
    homePage: '/committees/5/home',
    messageingPage: '/committees/5/messaging',
    managePage: '/committees/5/admin',
    isProgram: true,
    isCommitteeAdmin: false,
    isCommitteeMember: false
  },
  {
    id: 6,
    name: 'Full Moon',
    description: 'Description for the Full Moon Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 6,
    numMembers: 23,
    homePage: '/committees/6/home',
    messageingPage: '/committees/6/messaging',
    managePage: '/committees/6/admin',
    isProgram: false,
    isCommitteeAdmin: true,
    isCommitteeMember: true
  },
  {
    id: 7,
    name: 'Keep Lewisville Beautiful',
    description: 'Description for the Keep Lewisville Beautiful Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 7,
    numMembers: 23,
    homePage: '/committees/7/home',
    messageingPage: '/committees/7/messaging',
    managePage: '/committees/7/admin',
    isProgram: false,
    isCommitteeAdmin: false,
    isCommitteeMember: true
  },
  {
    id: 8,
    name: 'Photo Wall',
    description: 'Description for the Photo Wall Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 8,
    numMembers: 23,
    homePage: '/committees/8/home',
    messageingPage: '/committees/8/messaging',
    managePage: '/committees/8/admin',
    isProgram: true,
    isCommitteeAdmin: true,
    isCommitteeMember: true
  },
  {
    id: 9,
    name: 'Uniforms',
    description: 'Description for the Uniforms Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 9,
    numMembers: 23,
    homePage: '/committees/9/home',
    messageingPage: '/committees/9/messaging',
    managePage: '/committees/9/admin',
    isProgram: false,
    isCommitteeAdmin: false,
    isCommitteeMember: false
  },
  {
    id: 10,
    name: 'Warrants',
    description: 'Description for the Warrants Committee. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet harum inventore ipsum nisi quae sint vero? Eos itaque natus praesentium provident velit. Asperiores cupiditate deserunt, ducimus libero pariatur praesentium sunt.',
    chair: 'John D.',
    chairMemberId: 10,
    numMembers: 23,
    homePage: '/committees/10/home',
    messageingPage: '/committees/10/messaging',
    managePage: '/committees/10/admin',
    isProgram: true,
    isCommitteeAdmin: true,
    isCommitteeMember: true
  }
]

export const memberHours = [
  {
    id: 1,
    start: new Date(),
    end: new Date(),
    category: 'ADMIN',
    description: 'Website work',
    totalHours: 15
  },
  {
    id: 2,
    start: new Date(),
    end: new Date(),
    category: 'Active Shooter Actor',
    description: '',
    totalHours: 20
  },
  {
    id: 3,
    start: new Date(),
    end: new Date(),
    category: 'Funeral',
    description: '',
    totalHours: 4
  },
  {
    id: 4,
    start: new Date(),
    end: new Date(),
    category: 'Full Moon',
    description: 'Lorem ipsum',
    totalHours: 4
  },
  {
    id: 5,
    start: new Date(),
    end: new Date(),
    category: 'Full Moon',
    description: '',
    totalHours: 4
  }
]

export const memberDocuments = [
  {
    id: 1,
    name: 'Organization Bylaws',
    description: 'Org Bylaws',
    type: 'PDF',
    lastUpdate: new Date(),
    link: 'https://www.google.com'
  },
  {
    id: 2,
    name: 'Committee Policies',
    description: 'Committee Policies',
    type: 'DOCX',
    lastUpdate: new Date(),
    link: 'https://www.google.com'
  },
  {
    id: 3,
    name: 'Gmail Guide',
    description: 'Email guide for org Members',
    type: 'Google Docs',
    lastUpdate: new Date(),
    link: 'https://www.docs.google.com'
  },
]