interface NavbarSubMenuItemProps {
  name: string
  href: string
}

export interface NavbarDataProps {
  name: string
  href: string
  subMenuItems?: NavbarSubMenuItemProps[]
}

export const navbarData: NavbarDataProps[] = [
  {
    name: 'Home',
    href: '/'
  },
  {
    name: 'Member Resources',
    href: '/members',
    subMenuItems: [
      {
        name: 'Submit Hours',
        href: '/members/hours/submit'
      },
      {
        name: 'My Hours',
        href: '/members/hours'
      },
      {
        name: 'Documents',
        href: '/members/documents'
      },
      {
        name: 'Roster',
        href: '/members/roster'
      },
      {
        name: 'Messaging',
        href: '/members/messaging'
      }
    ]
  },
  {
    name: 'Calendar',
    href: '/calendar'
  },
  {
    name: 'Committees',
    href: '/committees'
  },
  {
    name: 'Reports',
    href: '/reports',
    subMenuItems: [
      {
        name: 'Prebuilt Reports',
        href: '/reports/prebuilt'
      },
      {
        name: 'Report Builder',
        href: '/reports/builder'
      }
    ]
  },
  {
    name: 'Admin',
    href: '/admin',
    subMenuItems: [
      {
        name: 'Users',
        href: '/admin/users'
      },
      {
        name: 'Settings',
        href: '/admin/settings'
      },
      {
        name: 'System Config',
        href: '/admin/config'
      }
    ]
  }
]
