import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    Container,
    Heading,
    HStack,
    IconButton,
    Select,
    Stack,
    Table,
    Card,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    Modal,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Icon,
    ModalCloseButton,
    ModalBody, ModalFooter, Tabs, TabList, Tab, TabIndicator, TabPanel, TabPanels, FormControl, FormLabel, Input
} from '@chakra-ui/react'
import {Member, members} from "./SAMPLE_DATA";
import {FiEye} from "react-icons/fi";
import './users.css'
import {useNavigate} from "react-router-dom";
import React from "react";
import {FaAlignJustify, FaExternalLinkAlt, FaRegClock} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import member from "./Member";

interface PositionColorData {
    [key: string]: string
}

function Members() {
    const navigate = useNavigate()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const isMobile = useBreakpointValue({ base: true, md: false })

    const positionColors: PositionColorData = {
        liaison: 'red',
        board: 'blue',
        general: 'green'}
    const positionOrder = ['liaison', 'board', 'general']

    const customSort = (a: Member, b: Member) => {
        // Compare ranks
        const positionA = positionOrder.indexOf(a.membershipPosition.id)
        const positionB = positionOrder.indexOf(b.membershipPosition.id)
        const positionComparison = positionA - positionB

        // If positions are different return the comparison
        if (positionComparison !== 0) {
            return positionComparison
        }

        // if positions are equal, compare website IDs
        return a.ids.webId - b.ids.webId
    }

    const sortedMembers = members.sort(customSort)

    function handleEmailReveal(e: any, webId: number) {
        if (webId === null) {
            return
        }
        // API Call to get email
        const mbr = members.filter((member: Member) => member.ids.webId === webId)
        e.target.textContent = mbr[0].email
        e.target.classList.remove('redactedInfo')

        setTimeout(() => {
            e.target.textContent = Array.from({ length: 15 }, () => Math.random().toString(36)[2]).join('')
            e.target.classList.add('redactedInfo')
        }, 3000)
    }
    return (
        <>
            <Modal size='4xl' isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent my='2' ml='2' mr='4'>
                    <ModalHeader>
                        Test Name
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Tabs size='lg' variant="line">
                            <TabList>
                                <Tab>General Info</Tab>
                                <Tab>Committee Info</Tab>
                                <Tab>Class Info</Tab>
                                <Tab>Training Info</Tab>
                                <Tab>Certification Info</Tab>
                            </TabList>
                            <TabIndicator />

                            <TabPanels>
                                <TabPanel>
                                    <Stack direction='row' spacing={2}>
                                        <Stack spacing={2} w='50%'>
                                            <FormControl id="firstName">
                                                <FormLabel>First Name</FormLabel>
                                                <Input defaultValue='First Name' variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                            <FormControl id="email">
                                                <FormLabel>E-Mail Address</FormLabel>
                                                <Input defaultValue='E-Mail Address' variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                            <FormControl id="membershipPosition">
                                                <FormLabel>Membership Position</FormLabel>
                                                <Input defaultValue='General Member' variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={2} w='50%'>
                                            <FormControl id="lastName">
                                                <FormLabel>Last Name</FormLabel>
                                                <Input defaultValue='Last Name' variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                            <FormControl id="joinDate">
                                                <FormLabel>Join Date</FormLabel>
                                                <Input defaultValue={new Date().toLocaleDateString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric'})} variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                            <FormControl id="status">
                                                <FormLabel>Status</FormLabel>
                                                <Input defaultValue='Active' variant='filled' borderColor='gray.600' color='gray.500' focusBorderColor='gray.600' _hover={{cursor: 'not-allowed'}} readOnly/>
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </TabPanel>
                                <TabPanel>
                                    <p>Committee Information (Committee Membership info, etc.)</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>Class Information (CPA class info, etc.)</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>Training Information (COP Classroom, COP in car, Skywatch, etc.)</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>Certification Information (COP, CPR, DD, etc.)</p>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}>
                <Heading color='white' size='lg'>Membership Roster</Heading>
                <Card>
                    <Stack spacing={2}>
                        <Box overflowX='auto' borderTopRadius='md'>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th textAlign='center'>Name</Th>
                                        <Th textAlign='center'>Join Date</Th>
                                        <Th textAlign='center'>E-Mail</Th>
                                        <Th textAlign='center'>Position</Th>
                                        <Th textAlign='center'>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody color='white'>
                                    {sortedMembers.map((member: Member) => (
                                        <Tr key={member.ids.webId}>
                                            <Td textAlign='center'>{member.name.fullName}</Td>
                                            <Td textAlign='center'>{member.joinDate.toLocaleDateString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric'})}</Td>
                                            <Td className='redactedInfo' textAlign='center' onClick={(e) => handleEmailReveal(e, member.ids.webId)}>
                                                {Array.from({ length: 15 }, () => Math.random().toString(36)[2]).join('')}
                                            </Td>
                                            <Td textAlign='center'>
                                                <Badge variant='solid' textTransform='capitalize' colorScheme={positionColors[member.membershipPosition.id]}>
                                                    {member.membershipPosition.positionName}
                                                </Badge>
                                            </Td>
                                            <Td>
                                                <HStack justify='center' spacing="1">
                                                    <IconButton icon={<FiEye />} variant="tertiary" aria-label="View member" onClick={onOpen}/>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box px={4} pb={2}>
                            <HStack spacing='3' justify='space-between'>
                                {!isMobile && ( // eslint-disable-line
                                    <Text color='muted' fontSize='sm'>
                                        Showing 1 to {members.length} of {members.length} member {members.length > 1 ? 's' : ''}
                                    </Text>
                                )}
                                <ButtonGroup
                                    spacing='3'
                                    justifyContent='space-between'
                                    width={{ base: 'full', md: 'auto' }}
                                    variant='secondary'
                                >
                                    <Select maxW='3xs' w='auto'>
                                        <option value='limit=5'>5 results</option>
                                        <option value='limit=10'>10 results</option>
                                        <option value='limit=25'>25 results</option>
                                        <option value='limit=50'>50 results</option>
                                    </Select>
                                    <ButtonGroup
                                        spacing='1'
                                        justifyContent='end'
                                        variant='secondary'
                                    >
                                        <Button>Previous</Button>
                                        <Button>Next</Button>
                                    </ButtonGroup>
                                </ButtonGroup>
                            </HStack>
                        </Box>
                    </Stack>
                </Card>
            </Container>
        </>
    );
}

export default Members;