import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react'
import { NavigateFunction } from 'react-router-dom'

interface NavButtonProps extends ButtonProps {
  icon: As
  label: string
  path: string
  navigateFunc: NavigateFunction
}

export const NavButton = (props: NavButtonProps): JSX.Element => {
  const { icon, label, path, navigateFunc, ...buttonProps } = props
  return (
    <Button variant='ghost' justifyContent='start' {...buttonProps} onClick={() => { navigateFunc(path) }}>
      <HStack spacing='3'>
        <Icon as={icon} boxSize='6' color='subtle' />
        <Text>{label}</Text>
      </HStack>
    </Button>
  )
}
