import React, { useState } from 'react'
import {
  Box,
  Button, ButtonGroup,
  Card,
  Container, Divider,
  HStack,
  IconButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr, useBreakpointValue
} from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { FiEdit2, FiEye, FiTrash2 } from 'react-icons/fi'
import { memberDocuments } from '../../../constants/testData'
import { truncate } from '../../../utils/utils'

const MemberDocuments = (): JSX.Element => {
  const [loaded, setLoaded] = useState<boolean>(false)
  setInterval(() => { setLoaded(true) }, 5000)
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}>
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Stack spacing='4' direction={{ base: 'row', lg: 'row' }} justify='space-between'>
            <PageHeader name='My Documents' description='View your documents' />
          </Stack>
          <Card minH='xs' overflowX='auto'>
            <Stack>
              <Box overflowX='auto'>
                <Table wordBreak='break-word'>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Description</Th>
                      <Th>File Type</Th>
                      <Th>Last Update</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {memberDocuments.map((memberDocument) => (
                      <Tr key={memberDocument.id}>
                        <Td>
                          <Text color='muted'>{memberDocument.name}</Text>
                        </Td>
                        <Td>
                          <Text color='muted'>{truncate(memberDocument.description, 50, true)}</Text>
                        </Td>
                        <Td>
                          <Text color='muted'>{memberDocument.type}</Text>
                        </Td>
                        <Td>
                          <Text color='muted'>{memberDocument.lastUpdate.toDateString()}</Text>
                        </Td>
                        <Td>
                          <HStack spacing='1'>
                            <IconButton
                              icon={<FiEye fontSize='1.25rem' />}
                              variant='ghost'
                              aria-label='View record'
                              onClick={() => {window.open(memberDocument.link, '_blank')}}
                            />
                            <IconButton
                              icon={<FiEdit2 fontSize='1.25rem' />}
                              variant='ghost'
                              aria-label='Edit record'
                            />
                            <Divider orientation='vertical' height='1rem' color='white' />
                            <IconButton
                              icon={<FiTrash2 fontSize='1.25rem' />}
                              variant='ghost'
                              aria-label='Delete record'
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box px={4} pb={2}>
                <HStack spacing='3' justify='space-between'>
                  {!isMobile && ( // eslint-disable-line
                    <Text color='muted' fontSize='sm'>
                      Showing 1 to {memberDocuments.length} of {memberDocuments.length} results
                    </Text>
                  )}
                  <ButtonGroup
                    spacing='3'
                    justifyContent='space-between'
                    width={{ base: 'full', md: 'auto' }}
                    variant='secondary'
                  >
                    <Button>Previous</Button>
                    <Button>Next</Button>
                  </ButtonGroup>
                </HStack>
              </Box>
            </Stack>
          </Card>
        </Stack>
      </Container>
    </>
  )
}

export default MemberDocuments
