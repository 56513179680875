import React, { useState } from 'react'
import {
  Box,
  Button, ButtonGroup,
  Card,
  Container, Select,
  HStack,
  IconButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr, useBreakpointValue
} from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { FiEdit2, FiEye, FiTrash2 } from 'react-icons/fi'
import { memberHours } from '../../../constants/testData'
import { truncate, getRandomInt } from '../../../utils/utils'

const MemberHourInfo = (): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const [monthHoursLoaded, setMonthHoursLoaded] = useState<boolean>(false)
  const [yearHoursLoaded, setYearHoursLoaded] = useState<boolean>(false)
  const [totalHoursLoaded, setTotalHoursLoaded] = useState<boolean>(false)
  const [entriesLoaded, setEntriesLoaded] = useState<boolean>(false)

  setInterval(() => { setMonthHoursLoaded(true) }, getRandomInt(500, 10000))
  setInterval(() => { setYearHoursLoaded(true) }, getRandomInt(3000, 10000))
  setInterval(() => { setTotalHoursLoaded(true) }, getRandomInt(1500, 10000))
  setInterval(() => { setEntriesLoaded(true) }, getRandomInt(5000, 10000))
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}>
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Stack spacing='4' direction={{ base: 'row', lg: 'row' }} justify='space-between'>
            <PageHeader name='My Hours' description='View your hours' />
            <Button colorScheme='green' alignSelf='center'>
              Submit Hours
            </Button>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='6'>
              <Skeleton borderRadius='md' isLoaded={monthHoursLoaded}>
                <Card minH='36' align='center'>
                  <Text>My Hours (Month)</Text>
                </Card>
              </Skeleton>

              <Skeleton borderRadius='md' isLoaded={yearHoursLoaded}>
                <Card minH='36' align='center'>
                  <Text>My Hours (YTD)</Text>
                </Card>
              </Skeleton>

              <Skeleton borderRadius='md' isLoaded={totalHoursLoaded}>
                <Card minH='36' align='center'>
                  <Text>My Hours (Total)</Text>
                </Card>
              </Skeleton>
            </SimpleGrid>
          </Stack>
          <Card minH='xs' overflowX='auto'>
            <Skeleton borderRadius='md' isLoaded={entriesLoaded}>
              <Stack>
                <Box overflowX='auto'>
                  <Table wordBreak='break-word'>
                    <Thead>
                      <Tr>
                        <Th>Start Time</Th>
                        <Th>End Time</Th>
                        <Th>Category</Th>
                        <Th>Description</Th>
                        <Th>Total Hours</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {memberHours.map((memberHourRecord) => (
                        <Tr key={memberHourRecord.id}>
                          <Td>
                            <Text color='muted'>{memberHourRecord.start.toLocaleString()}</Text>
                          </Td>
                          <Td>
                            <Text color='muted'>{memberHourRecord.end.toLocaleString()}</Text>
                          </Td>
                          <Td>
                            <Text color='muted'>{memberHourRecord.category}</Text>
                          </Td>
                          <Td>
                            <Text color='muted'>{truncate(memberHourRecord.description, 50, true)}</Text>
                          </Td>
                          <Td>
                            <Text color='muted'>{memberHourRecord.totalHours}</Text>
                          </Td>
                          <Td>
                            <HStack spacing='1'>
                              <IconButton
                                icon={<FiEye fontSize='1.25rem' />}
                                variant='ghost'
                                aria-label='View record'
                              />
                              <IconButton
                                icon={<FiEdit2 fontSize='1.25rem' />}
                                variant='ghost'
                                aria-label='Edit record'
                              />
                              {/*<Divider orientation='vertical' height='1rem' color='white' />*/}
                              {/*<IconButton*/}
                              {/*  icon={<FiTrash2 fontSize='1.25rem' />}*/}
                              {/*  variant='ghost'*/}
                              {/*  aria-label='Delete record'*/}
                              {/*/>*/}
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <Box px={4} pb={2}>
                  <HStack spacing='3' justify='space-between'>
                    {!isMobile && ( // eslint-disable-line
                      <Text color='muted' fontSize='sm'>
                        Showing 1 to {memberHours.length} of {memberHours.length} submissions
                      </Text>
                    )}
                    <ButtonGroup
                      spacing='3'
                      justifyContent='space-between'
                      width={{ base: 'full', md: 'auto' }}
                      variant='secondary'
                    >
                      <Select maxW='3xs' w='auto'>
                        <option value='limit=5'>5 results</option>
                        <option value='limit=10'>10 results</option>
                        <option value='limit=25'>25 results</option>
                        <option value='limit=none'>All results</option>
                      </Select>
                      <ButtonGroup
                          spacing='1'
                          justifyContent='end'
                          variant='secondary'
                      >
                        <Button>Previous</Button>
                        <Button>Next</Button>
                      </ButtonGroup>
                    </ButtonGroup>
                  </HStack>
                </Box>
              </Stack>
            </Skeleton>
          </Card>
        </Stack>
      </Container>
    </>
  )
}

export default MemberHourInfo
