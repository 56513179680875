import { Box, useColorModeValue, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { NavbarDataProps, navbarData } from '../../constants/NavbarData'
import DesktopNavbar from './DesktopNavbar/Desktop'
import MobileNavbar from './MobileNavbar/Mobile'
import { useNavigate } from 'react-router-dom'

const Navbar = (): JSX.Element => {
  const [navbarActive, setNavbarActive] = useState<NavbarDataProps | null>(null)
  const isMobile = useBreakpointValue<boolean>({ base: true, lg: false })
  const navigate = useNavigate()

  useEffect(() => {
    setNavbarActive(navbarData[navbarData.findIndex(obj => obj.href === `/${window.location.pathname.split('/')[1]}`)])
  }, [window.location.pathname]) // eslint-disable-line

  return (
    <>
      <Box as='section' zIndex={2}>
        <Box as='nav' bg='bg-surface' boxShadow={useColorModeValue('sm', 'sm-dark')} position='fixed' width='100%'>
          {isMobile // eslint-disable-line
            ? <MobileNavbar navbarActive={navbarActive} navigate={navigate} />
            : <DesktopNavbar navbarActive={navbarActive} navigate={navigate} />}
        </Box>
      </Box>
    </>
  )
}

export default Navbar
