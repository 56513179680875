import React from 'react';
import {Box, Heading, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {FiArrowDownRight, FiArrowUpRight} from "react-icons/all";

interface StatProps {
    label: string
    value: string
    trend: {
        value: string
        isPositive: boolean
        compText: string
    }
}

interface ProgressStatProps {
    label: string
    value: string
    limit: string
}

export function Stat(props: StatProps) {
    const { label, value, trend } = props
    return (
        <Stack>
            <HStack justify="space-between">
                <Text textStyle="sm" color="fg.muted">
                    {label}
                </Text>
            </HStack>
            <Stack spacing="4">
                <Heading size={{ base: 'sm', md: 'md' }}>{value}</Heading>
                <HStack spacing="1" fontWeight="medium">
                    <Icon
                        color={trend.isPositive ? 'success' : 'error'}
                        as={trend.isPositive ? FiArrowUpRight : FiArrowDownRight}
                        boxSize="5"
                    />
                    <Text color={trend.isPositive ? 'success' : 'error'}>{trend.value}</Text>
                    <Text color="fg.muted">{trend.compText}</Text>
                </HStack>
            </Stack>
        </Stack>
    );
}

export function ProgressStat(props: ProgressStatProps): React.ReactElement {
    const { label, value, limit } = props
    return (
        <Stack>
            <Text textStyle="sm" color="fg.muted">
                {label}
            </Text>
            <Stack direction="row" align="baseline">
                <Heading size={{ base: 'sm', md: 'md' }}>{value}</Heading>
                <Text aria-hidden fontWeight="semibold" color="fg.muted">
                    / {limit}
                </Text>
                <Box srOnly>out of {limit}</Box>
            </Stack>
        </Stack>
    )
}