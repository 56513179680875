import {
  Button,
  Container,
  Heading,
  VStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { GoogleIcon } from './ProviderIcons'
import { googleOauthLink } from "../../../constants/config";
import React from "react";

const Login = () => {
  return (
    <Container maxW="xl" py={{ base: '12', md: '24' }}>
      <Stack spacing="8">
        <Stack spacing="14">
          <Heading size='md' textAlign='center'>LCPAAA</Heading>
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
            <Text color="red">Authorized users only</Text>
          </Stack>
        </Stack>
        <Button variant="secondary" leftIcon={<GoogleIcon />} onClick={() => window.location.href = googleOauthLink}>
          Continue with Google
        </Button>
        <VStack spacing="3" justify="center">
          <Text textStyle="sm" color="fg.muted" align='center'>
            Having issues?
          </Text>
          <Text textStyle="sm" color="fg.muted" align='center'>
            Reach out to a board member via email or at the next meeting.
          </Text>
        </VStack>
      </Stack>
    </Container>
  )
}

export default Login