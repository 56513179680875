import React, { useEffect } from 'react'
import {
  Box,
  Button,
  CloseButton,
  Container,
  Icon,
  Stack,
  Text,
  useBreakpointValue, useDisclosure
} from '@chakra-ui/react'
import { FiAlertTriangle } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

const HoursBannerAlert = (): JSX.Element => {
  const navigate = useNavigate()
  const isMobile = useBreakpointValue<boolean>({ base: true, md: false }, { ssr: false })
  const { isOpen, onClose, onOpen } = useDisclosure()
  const tst = false

  useEffect(() => {
    if (tst) {
      onOpen()
    }
  })
  return (
    <>
      <Box as='section' py={2} hidden={!isOpen}>
        <Box bg='orange.500' boxShadow='sm'>
          <Container py={{ base: '4', md: '2.5' }} position='relative'>
            <CloseButton display={{ sm: 'none' }} position='absolute' right='2' top='2' onClick={() => { onClose() }} />
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justify='space-between'
              spacing={{ base: '3', md: '2' }}
            >
              <Stack
                spacing='4'
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'start', md: 'center' }}
              >
                {!isMobile && (
                  <Icon as={FiAlertTriangle} boxSize='6' />
                )}
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '0.5', md: '1.5' }}
                  pe={{ base: '4', sm: '0' }}
                >
                  <Text fontWeight='medium'>Ensure that you submit all of your hours!</Text>
                </Stack>
              </Stack>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                spacing={{ base: '3', sm: '2' }}
                align={{ base: 'stretch', sm: 'center' }}
              >
                <Button colorScheme='blue' variant='outline' width='full' onClick={() => { navigate('/hours/submit') }}>
                  Submit Hours
                </Button>
                <CloseButton display={{ base: 'none', sm: 'inline-flex' }} onClick={() => { onClose() }} />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default HoursBannerAlert
