import { Badge, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import {
  FiCalendar,
  FiEdit,
  FiHome,
  FiUsers,
  FiSettings
} from 'react-icons/fi'
import { NavButton } from '../common/NavButton'
import * as React from 'react'
import { MdMessage } from 'react-icons/all'
import { HiOutlineDocument } from 'react-icons/hi'
import {committeeSidebarProps} from "../common/Props";

const DesktopCommitteeSidebar = ({ committeeData, basePath, navActive, navigate }: committeeSidebarProps): JSX.Element => {
  return (
    <Flex as='section' minH='calc(100vh - 72px)' bg='bg-canvas' borderTopColor='gray.500' borderTopWidth='medium' mt='72px'>
      <Flex
        flex='1'
        bg='bg-surface'
        boxShadow='sm'
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
      >
        <Stack justify='space-between' spacing='1' width='full'>
          <Stack spacing='4' shouldWrapChildren>
            <Stack spacing='1' pb={2}>
              <Heading size='xs' noOfLines={1} alignSelf='center'>{committeeData.name}</Heading>
              {committeeData.isProgram ? <Badge alignSelf='center' colorScheme='blue' variant='solid'>PROGRAM</Badge> : <></>}
            </Stack>
            <Stack spacing='1'>
              <NavButton label='Home' icon={FiHome} path={`${basePath}/home`} aria-current={navActive === 'home' ? 'page' : 'false'} navigateFunc={navigate} />
            </Stack>
            <Stack>
              <Text fontSize='sm' color='subtle' fontWeight='medium'>
                Members
              </Text>
              <Stack spacing='1'>
                {/*<NavButton label='Documents' icon={HiOutlineDocument} path={`${basePath}/documents`} aria-current={navActive === 'documents' ? 'page' : 'false'} navigateFunc={navigate} />*/}
                {/*<NavButton label='Forms' icon={FiEdit} path={`${basePath}/forms`} aria-current={navActive === 'forms' ? 'page' : 'false'} navigateFunc={navigate} />*/}
                <NavButton label='Calendar' icon={FiCalendar} path={`${basePath}/calendar`} aria-current={navActive === 'calendar' ? 'page' : 'false'} navigateFunc={navigate} />
                <NavButton label='Messaging' icon={MdMessage} path={`${basePath}/messaging`} aria-current={navActive === 'messaging' ? 'page' : 'false'} navigateFunc={navigate} />
              </Stack>
            </Stack>
            <Stack>
              <Text fontSize='sm' color='subtle' fontWeight='medium'>
                Committee Administration
              </Text>
              <Stack spacing='1'>
                <NavButton label='Members' icon={FiUsers} path={`${basePath}/roster`} aria-current={navActive === 'members' ? 'page' : 'false'} navigateFunc={navigate} />
                {/*<NavButton label='Settings' icon={FiSettings} path={`${basePath}/admin`} aria-current={navActive === 'settings' ? 'page' : 'false'} navigateFunc={navigate} />*/}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default DesktopCommitteeSidebar
