import { useState } from 'react'
import { Button, Divider, Flex, FormControl, FormLabel, Stack, Textarea } from '@chakra-ui/react'
import DatePicker2 from '../DatePicker/datepicker'


const RideAlongRequestForm = (): JSX.Element => {

    const [rideDatetime, setRideDatetime] = useState<Date | undefined>(new Date())
    return (
        <>
            <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }} w='100%' h='100%'>
                <FormControl id='description'>
                    <FormLabel>Comments</FormLabel>
                    <Textarea placeholder='Event description' />
                </FormControl>
                <FormControl id='startDatetime' zIndex={2}>
                    <FormLabel>Ride Along Date & Time</FormLabel>
                    <DatePicker2 onChange={(date) => setRideDatetime(date)} selectedDate={rideDatetime}/>
                </FormControl>
            </Stack>
            <Divider />
            <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
                <Button type='submit' colorScheme='green'>
                    Submit Request
                </Button>
            </Flex>
        </>
    )
}

export default RideAlongRequestForm
