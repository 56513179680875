import { ChakraProvider, ColorModeScript, extendTheme, theme as baseTheme } from '@chakra-ui/react'
import { theme as proTheme } from '@chakra-ui/pro-theme'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import Router from './router/Router'
import reportWebVitals from './reportWebVitals'

import '@fontsource/inter/variable.css'
import 'react-datepicker/dist/react-datepicker.css'

const container = document.getElementById('root')
if (container == null) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

const config = {
  colors: { ...baseTheme.colors, brand: baseTheme.colors.purple },
  initialColorMode: 'dark',
  useSystemColorMode: false
}
export const theme = extendTheme({ config }, proTheme)

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <Router />
    </ChakraProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals() // eslint-disable-line
