export const members: Member[] = [{
  ids: {
    webId: 1,
    googleId: null,
    ipAddress: '192.168.1.1'
  },
  name: {
    firstName: 'John',
    lastName: 'Doe',
    fullName: 'John Doe'
  },
  joinDate: new Date(2020, 0, 1),
  email: 'johnd@gmail.com',
  status: {
    isActive: true,
    isHonorary: true
  },
  committees: [
    {
      id: '1',
      name: 'Ashton\'s Room',
      role: 'chair'
    },
    {
      id: '2',
      name: 'Citizen\'s On Patrol',
      role: 'none'
    },
    {
      id: '3',
      name: 'Battle of the Badge',
      role: 'member'
    },
  ],
  membershipPosition: {
    id: 'board',
    positionName: 'President'
  },
  cpaClass: {
    classNum: 42,
    startDate: new Date(2019, 9, 1),
    endDate: new Date(2020, 1, 14),
    graduationDate: new Date(2020, 1, 14),
    pass: true
  }
}]

interface Identifiers {
  webId: number,
  googleId: null | string,
  ipAddress: null | string // ToDo: Remove and require special API call to retrieve
}

interface Name {
  firstName: string,
  lastName: string,
  fullName: string
}

interface MemberStatus {
  isActive: boolean,
  isHonorary: boolean
}

interface MembershipPosition {
  id: string // Inactive, Liaison, Board, General
  positionName: string | null // Only for board positions, ignored otherwise
}

interface Committees {
  id: string,
  name: string,
  role: string, // Chair, Member or None
}

interface CpaClass {
  classNum: number,
  startDate: Date,
  endDate: Date,
  graduationDate: Date,
  pass: boolean
}

export interface Member {
  ids: Identifiers,
  name: Name,
  joinDate: Date,
  email: string, // ToDo: Remove and require special API call to retrieve
  status: MemberStatus,
  committees: Committees[],
  membershipPosition: MembershipPosition,
  cpaClass: CpaClass,
}