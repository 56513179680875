import React, { useRef } from 'react'
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Avatar,
  Box, Button,
  ButtonGroup, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay,
  Flex,
  Heading,
  HStack, Icon,
  IconButton, Link,
  Menu, MenuButton, MenuDivider, MenuItem, MenuList,
  StackDivider, Text, useColorModeValue, useDisclosure, VStack
} from '@chakra-ui/react'
import { ColorModeSwitcher } from '../../ColorModeSwitcher/ColorModeSwitcher'
import { websiteHelpMailto } from '../../../constants/config'
import { FiHelpCircle, FiMenu } from 'react-icons/fi'
import { navbarData } from '../../../constants/NavbarData'
import { MdClose } from 'react-icons/md'
import { DesktopNavbarProps } from '../common/props'

const MobileNavbar = ({ navbarActive, navigate }: DesktopNavbarProps): JSX.Element => {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const MenuIcon = isOpen ? MdClose : FiMenu
  const textColor = useColorModeValue('gray.700', 'gray.100')
  return (
    <>
      <Flex height='16' align='center' justify='space-between' px='5'>
        <Heading size='sm'>ORG</Heading>
        <HStack divider={<StackDivider height='6' alignSelf='unset' />}>
          <>
            <ButtonGroup variant='ghost' spacing='1'>
              <ColorModeSwitcher />
              {/*<IconButton as='a' href={websiteHelpMailto} icon={<FiHelpCircle fontSize='1.25rem' />} aria-label='Help' />*/}
              <Menu>
                <MenuButton
                  as={Button}
                  rounded='full'
                  variant='link'
                  cursor='pointer'
                  minW={0}
                >
                  <Avatar boxSize='10' name='Christoph Winston' src='https://tinyurl.com/yhkm2ek8' />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => { navigate('/account') }}>Profile</MenuItem>
                  <MenuItem onClick={() => { navigate('/account/settings') }}>Settings</MenuItem>
                  <MenuDivider borderColor='initial' />
                  <MenuItem color='red.400' fontWeight='bold'>Logout</MenuItem>
                </MenuList>
              </Menu>
            </ButtonGroup>
          </>
          <IconButton
            ref={menuButtonRef}
            variant='ghost'
            icon={<Icon as={MenuIcon} fontSize='2xl' />}
            aria-label='Open Menu'
            onClick={onOpen}
          />
        </HStack>
      </Flex>

      <Drawer
        placement='left'
        initialFocusRef={menuButtonRef}
        isOpen={isOpen}
        onClose={onClose}
        size='full'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader padding='0'>
            <Flex height='16' align='center' justify='space-between' px='5'>
              <Heading size='sm'>LCPAAA</Heading>
              <HStack divider={<StackDivider height='6' alignSelf='unset' />}>
                <IconButton
                  ref={menuButtonRef}
                  variant='ghost'
                  icon={<Icon as={MenuIcon} fontSize='2xl' />}
                  aria-label='Open Menu'
                  onClick={onClose}
                />
              </HStack>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <VStack align='left'>
              {navbarData.map((item) => (
                item.subMenuItems !== undefined
                  ? <Accordion key={item.name} allowMultiple as='ul' listStyleType='none'>
                    <AccordionItem key={item.name} as='li'>
                      <AccordionButton px='0'>
                        <Box flex='1' textAlign='start' fontSize='lg' fontWeight='semibold'>
                          {item.name}
                        </Box>
                        <AccordionIcon fontSize='xl' />
                      </AccordionButton>
                      <AccordionPanel pb='3' px='0' pt='0'>
                        <Link
                          onClick={(e) => { e.preventDefault(); navigate(item.href); onClose() }}
                          _hover={{ textDecoration: 'none' }}
                        >
                          <HStack py='3' spacing='2'>
                            <Text fontWeight='medium'>
                              {item.name} Home
                            </Text>
                          </HStack>
                        </Link>

                        {item.subMenuItems?.map((subMenuItem, index) => (
                          <Link
                            key={index}
                            onClick={(e) => { e.preventDefault(); navigate(subMenuItem.href); onClose() }}
                            _hover={{ textDecoration: 'none' }}
                          >
                            <HStack py='3' spacing='2'>
                              <Text fontWeight='medium'>
                                {subMenuItem.name}
                              </Text>
                            </HStack>
                          </Link>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion> // eslint-disable-line
                  : <HStack mt='1' key={item.name}>
                    <Button
                      flex='1'
                      onClick={() => { navigate(item.href); onClose() }}
                      variant='ghost'
                      justifyContent='start'
                      fontSize='lg'
                      fontWeight='semibold'
                      color={textColor}
                      paddingInlineStart={0}
                      aria-current={item === navbarActive ? 'page' : 'false'}
                    >
                      {item.name}
                    </Button>
                  </HStack>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavbar
