import React from 'react'
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'

import {Container, Heading, useBreakpointValue, Button} from '@chakra-ui/react'

import App from '../pages/App'
import Home from '../pages/Home/Home'
import CommitteeHome from '../pages/Committees/CommitteePages/CommitteeHome/CommitteeHome'
import CommitteeList from '../pages/Committees/CommitteeList/CommitteeList'
import Hud from '../pages/Committees/CommitteePages/hud'
import Login from '../pages/Authentication/Login/Login'
import MemberInfoPage from '../pages/MemberResources/MemberInfoPage/MemberInfoPage'
import MemberHoursSubmit from '../pages/MemberResources/MemberHoursSubmit/MemberHoursSubmit'
import MemberHourInfo from '../pages/MemberResources/MemberHourInfo/MemberHourInfo'
import MemberDocuments from "../pages/MemberResources/MemberDocuments/MemberDocuments";
import Calendar from '../pages/Calendar/Calendar'
import Users from "../pages/MemberResources/MembershipRoster/MembershipRoster";

const Router = (): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<h1> Logout </h1>} />

        <Route path='/' element={<App />}>
          {/* CommitteeHome Page */}
          <Route index element={<Home />} />

          {/* Calendar Page */}
          <Route path='calendar' element={<Calendar />} />

          {/* Committee Pages */}
          <Route path='committees'>
            <Route index element={<CommitteeList />} />
            {/* <Route path='list' element={<CommitteeList />} /> */}
            {/* <Route path='search' element={<Container pt={{ base: '6', lg: '8' }} mt='129px'><Heading size='lg'>Committees Search</Heading></Container>} /> */}

            <Route path=':committeeId' element={<Hud />}>
              <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '72px'}><Heading size='lg'>COMMITTEE HOME REDIRECT NEEDED</Heading></Container>} />
              <Route path='home' element={<CommitteeHome />} />
              <Route path='documents'>
                <Route index element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Documents</Heading></Container>} />

                <Route path=':documentId'>
                  <Route index element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Document</Heading></Container>} />
                </Route>
              </Route>
              <Route path='forms'>
                <Route index element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Forms</Heading></Container>} />

                <Route path=':formId'>
                  <Route index element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Form</Heading></Container>} />
                  <Route path='responses' element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Form Responses</Heading></Container>} />
                </Route>
              </Route>
              <Route path='calendar' element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Calendar</Heading></Container>} />
              <Route path='messaging' element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Messenger</Heading></Container>} />
              <Route path='roster' element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Roster</Heading></Container>} />
              <Route path='admin' element={<Container mt={isMobile ? '6' : '72px'}><Heading size='lg'>Committee Admin</Heading></Container>} />
            </Route>
          </Route>

          {/* Members Pages */}
          <Route path='members'>
            <Route index element={<MemberInfoPage />} />
            <Route path='messaging' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Member Messaging</Heading></Container>} />
            <Route path='roster' element={<Users /> } />

            <Route path='hours'>
              <Route index element={<MemberHourInfo />} />
              <Route path='submit' element={<MemberHoursSubmit />} />
            </Route>

            <Route path='documents'>
              <Route index element={<MemberDocuments />} />
              <Route path=':documentId' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Document Information & Viewer?</Heading></Container>} />
            </Route>
          </Route>

          {/* Report Pages */}
          <Route path='reports'>
            <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Reports</Heading></Container>} />
            <Route path='builder' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Report Builder</Heading></Container>} />
            <Route path='prebuilt' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Prebuilt Reports</Heading></Container>} />
          </Route>

          {/* Admin Pages */}
          <Route path='admin'>
            <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Admin</Heading></Container>} />
            <Route path='users'>
              <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>User List</Heading></Container>} />
              <Route path=':userId'>
                <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>User Profile</Heading></Container>} />
                <Route path='settings' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>User Settings</Heading></Container>} />
              </Route>
            </Route>
            <Route path='settings' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>Settings</Heading></Container>} />
            <Route path='config' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>System Configuration</Heading></Container>} />
          </Route>

          {/* User Pages */}
          <Route path='account'>
            <Route index element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>User Profile</Heading></Container>} />
            <Route path='settings' element={<Container pt={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}><Heading size='lg'>User Settings</Heading></Container>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
