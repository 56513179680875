import React from 'react'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { getCommitteeData } from '../../../../utils/utils'
import { useOutletContext } from 'react-router-dom'
import {Card, SimpleGrid, Stack, Text, useBreakpointValue} from '@chakra-ui/react'

const CommitteeHome = (): JSX.Element => {
  const committeeId = useOutletContext<number>()
  const committeeData = getCommitteeData(committeeId)

  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <>
      <Stack mt={isMobile ? '6' : '72px'} mb={{base: '6', md: '8'}}>
        <PageHeader name={`${committeeData.name} Home`} description={`${committeeData.name} Information & Resources`} />
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Card minH='xs' align='center'>
            <Text>Committee Information</Text>
          </Card>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='6'>
              <Stack spacing={{ base: '5', lg: '6' }}>
                <Card minH='36' align='center'>
                  <Text>My Hours (YR & MO)</Text>
                </Card>
                <Card minH='36' align='center'>
                  <Text>Committee Hours (YR & MO)</Text>
                </Card>
              </Stack>

              <Stack spacing={{ base: '5', lg: '6' }}>
                <Card minH='36' align='center'>
                  <Text>Next Event</Text>
                </Card>
                <Card minH='36' align='center'>
                  <Text>Placeholder</Text>
                </Card>
              </Stack>

              <Card minH='36' align='center'>
                <Text>Documents</Text>
              </Card>
            </SimpleGrid>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='6'>
              <Card minH='xs' align='center'>
                <Text>Submit Hours</Text>
              </Card>
              <Card minH='xs' align='center'>
                <Text>Placeholder</Text>
              </Card>
            </SimpleGrid>
          </Stack>
          <Card minH='xs' align='center'>
            <Text>Placeholder</Text>
          </Card>
        </Stack>
      </Stack>
    </>
  )
}

export default CommitteeHome
