import React, { useState } from 'react'
import {
  Box,
  Card,
  Container,
  Heading,
  Progress,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import HourSubmitForm from "../../../components/HourSubmitForm/HourSubmitForm";
import RideAlongRequestForm from "../../../components/RideAlongRequestForm/RideAlongRequestForm";
import {ProgressStat, Stat} from "../../../components/Stats/Stat";

const MemberInfoPage = (): JSX.Element => {
  const [loaded, setLoaded] = useState<boolean>(false)
  setInterval(() => { setLoaded(true) }, 5000)
  const isMobile = useBreakpointValue<boolean>({base: true, md: false})
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}>
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='6'>
              <Skeleton borderRadius='md' isLoaded={loaded}>
                <Card minH='36' display='grid'>
                  <Stack align='center' justify='space-evenly'>
                    <Heading size='md'>Next Meeting</Heading>
                    <Heading size='xs'>Tuesday, June 11, 2024</Heading>
                  </Stack>
                </Card>
              </Skeleton>

              <Skeleton borderRadius='md' isLoaded={loaded}>
                <Card minH='36' display='grid'>
                  <Box px={'2'} justifySelf='center' alignSelf='center'>
                    <Stat label='My Hours (YTD)' value='100 Hours' trend={{isPositive: false, value: '20 Hours', compText: 'vs last month'}} />
                  </Box>
                </Card>
              </Skeleton>

              <Skeleton borderRadius='md' isLoaded={loaded}>
                <Card minH='36' display='grid'>
                  <Box px={'2'} justifySelf='center' alignSelf='center'>
                    <ProgressStat label='Total Hours' value='1300' limit='5000' />
                  </Box>
                  <Progress alignSelf='end' value={(1300 / 5000) * 100} size="md" bg="bg.surface" colorScheme="blue" />
                </Card>
              </Skeleton>
            </SimpleGrid>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='6'>
              <Card minH='xs' align='center'>
                <Heading size='md'>Submit Hours</Heading>
                <HourSubmitForm borderColor='gray.500'/>
              </Card>
              <Card minH='xs' align='center'>
                <Heading size='md'>Ride Along Request</Heading>
                <RideAlongRequestForm />
              </Card>
            </SimpleGrid>
          </Stack>
          <Card minH='xs' align='center'>
            <Text>Placeholder</Text>
          </Card>
        </Stack>
      </Container>
    </>
  )
}

export default MemberInfoPage
