import React, {useState} from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Container, Skeleton, Spinner,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'

import '../../../components/DatePicker/datepicker.css'
import HourSubmitForm from '../../../components/HourSubmitForm/HourSubmitForm'
import PageHeader from '../../../components/PageHeader/PageHeader'
import {getRandomInt} from "../../../utils/utils";

const MemberHoursSubmit = (): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const isMobile = useBreakpointValue<boolean>({base: true, md: false})

  const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(false)
  setInterval(() => { setCategoriesLoaded(true) }, getRandomInt(500, 10000))
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt={isMobile ? '65px' : '129px'}>
        <PageHeader name='Submit Hours' description='Submit your volunteer hours' />
        <Alert status='success' variant='left-accent' hidden={!isOpen}>
          <AlertIcon />
          <Box>
            <AlertTitle>Success!</AlertTitle>
            <AlertDescription>
              Your hours have been submitted!
            </AlertDescription>
          </Box>
          <CloseButton
            position='absolute'
            right={2}
            top={2}
            onClick={onClose}
          />
        </Alert>

        <form onSubmit={(e) => { e.preventDefault(); console.log(e); onOpen() }}>
          <Skeleton borderRadius='md' isLoaded={categoriesLoaded}>
            <HourSubmitForm />
          </Skeleton>
        </form>
      </Container>
    </>
  )
}

export default MemberHoursSubmit
