import { committeeDataProps, committees } from '../constants/testData'

export function getCommitteeData (committeeId: number): committeeDataProps {
  return committees[committees.findIndex(obj => committeeId === obj.id)]
}

export function truncate (str: string, n: number, useWordBoundary: boolean): string {
  if (str.length <= n) { return str }
  const subString = str.slice(0, n - 1) // the original check
  return (useWordBoundary
    ? subString.slice(0, subString.lastIndexOf(' '))
    : subString) + '...'
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}
