import React, { useState } from 'react'
import {
  Card,
  SimpleGrid,
  Stack,
  Heading,
  Text,
  Container,
  Skeleton,
  Progress,
  StatLabel,
  StatNumber, StatHelpText, Box, HStack
} from '@chakra-ui/react'
import {FiArrowUpRight, FiMoreVertical} from "react-icons/all";
import {Stat, ProgressStat} from "../../components/Stats/Stat";
import HourSubmitForm from "../../components/HourSubmitForm/HourSubmitForm";
import RideAlongRequestForm from "../../components/RideAlongRequestForm/RideAlongRequestForm";

const Home = (): JSX.Element => {
  const [loaded, setLoaded] = useState<boolean>(false)
  setInterval(() => { setLoaded(true) }, 5000)
  return (
    <>
      <Container py={{ base: '6', lg: '8' }} mt='72px'>
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Stack spacing='4' direction={{ base: 'column', lg: 'row' }} justify='space-between'>
            <Heading size={{ base: 'xs', lg: 'sm' }} fontWeight='medium'>
              Dashboard
            </Heading>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='6'>
              <Card minH='36' display='grid'>
                <Box px={'2'} justifySelf='center' alignSelf='center'>
                  <Stat label='My June Hours' value='10 Hours' trend={{isPositive: false, value: '20 Hours', compText: 'vs last month'}} />
                </Box>
              </Card>

              <Skeleton borderRadius='md' isLoaded={loaded}>
                <Card minH='36' display='grid'>
                  <Box px={'2'} justifySelf='center' alignSelf='center'>
                    <Stat label='Top Committee' value='Battle of the Badge' trend={{isPositive: true, value: '500 Hours', compText: 'vs last month'}} />
                  </Box>
                </Card>
              </Skeleton>

              <Skeleton borderRadius='md' isLoaded={loaded}>
                <Card minH='36' display='grid'>
                  <Box px={'2'} justifySelf='center' alignSelf='center'>
                    <ProgressStat label='Total Hours' value='1300' limit='5000' />
                  </Box>
                  <Progress alignSelf='end' value={(1300 / 5000) * 100} size="md" bg="bg.surface" colorScheme="blue" />
                </Card>
              </Skeleton>
            </SimpleGrid>
          </Stack>
          <Stack spacing={{ base: '5', lg: '6' }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='6'>
              <Card minH='xs' align='center'>
                <Heading size='md'>Submit Hours</Heading>
                <HourSubmitForm borderColor='gray.500'/>
              </Card>
              <Card minH='xs' align='center'>
                <Heading size='md'>Ride Along Request</Heading>
                <RideAlongRequestForm />
              </Card>
            </SimpleGrid>
          </Stack>
          <Card minH='xs' align='center'>
            <Text>Placeholder</Text>
          </Card>
        </Stack>
      </Container>
    </>
  )
}

export default Home
