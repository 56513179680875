import * as React from 'react'
import DesktopCommitteeSidebar from "./Desktop/Desktop";
import MobileCommitteeSidebar from "./Mobile/Mobile";
import {committeeSidebarProps} from "./common/Props";

const CommitteeSidebar = ({ isMobile, committeeData, navigate, basePath, navActive }: committeeSidebarProps): JSX.Element => {
  return (
    <>
      {isMobile ?
        <MobileCommitteeSidebar committeeData={committeeData} navigate={navigate} basePath={basePath} navActive={navActive} /> :
        <DesktopCommitteeSidebar committeeData={committeeData} navigate={navigate} basePath={basePath} navActive={navActive} />
      }
    </>
  )
}

export default CommitteeSidebar
