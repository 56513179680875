import * as React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import HoursBannerAlert from '../components/Alerts/BannerAlert'
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

const App = (): JSX.Element => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <HoursBannerAlert />
      <Outlet />
    </>
  )
}

export default App
