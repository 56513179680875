import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import CommitteeSidebar from '../../../components/CommitteeSidebar/CommitteeSidebar'
import {Container, Stack, useBreakpointValue} from '@chakra-ui/react'
import {getCommitteeData} from "../../../utils/utils";

const Hud = (): JSX.Element => {
  const [navActive, setNavActive] = useState<string>()
  const [basePath, setBasePath] = useState<string>('')
  const navigate = useNavigate()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    const pathItems = window.location.pathname.toLowerCase().split('/')
    setNavActive(pathItems[pathItems.length - 1])
    setBasePath(`/committees/${committeeData.id}`)
  }, [window.location.pathname]) // eslint-disable-line

  const params = useParams()
  if (params.committeeId === undefined) {
    return <></>
  }

  const committeeData = getCommitteeData(parseInt(params.committeeId))
  return (
    <>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <CommitteeSidebar isMobile={isMobile} committeeData={committeeData} navigate={navigate} basePath={basePath} navActive={navActive} />

        <Container>
          <Outlet context={parseInt(params.committeeId)} />
        </Container>
      </Stack>
    </>
  )
}

export default Hud
