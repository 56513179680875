import React, { forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useColorModeValue, InputGroup, Input, InputRightElement } from '@chakra-ui/react'

import { BsCalendar } from 'react-icons/bs'

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

const customDateInput = ({ value, onClick, onChange }: any, ref: any): JSX.Element => (
  <Input
    autoComplete='off'
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
  />
)
customDateInput.displayName = 'DateInput'

const CustomInput = forwardRef(customDateInput)

const icon = <BsCalendar fontSize='sm' />

interface Props {
  isClearable?: boolean
  onChange: (date: Date) => any
  selectedDate: Date | undefined
  showPopperArrow?: boolean
}

const DatePicker2 = ({ selectedDate, onChange, ...props }: Props): JSX.Element => {
  const dark = useColorModeValue(false, true)
  return (
    <>
      <InputGroup className={dark ? 'dark-theme' : 'light-theme'}>
        <ReactDatePicker
          selected={selectedDate}
          onChange={onChange}
          className='react-datapicker__input-text'
          customInput={<CustomInput />}
          dateFormat='MM/dd/yyyy HH:mm'
          showTimeSelect
          timeFormat='HH:mm'
          timeIntervals={15}
          showPopperArrow={false}
          {...props}
        />
        <InputRightElement color='gray.500'>
          {icon}
        </InputRightElement>
      </InputGroup>
    </>
  )
}

export default DatePicker2
