import React from 'react';
import {Badge, Box, Heading, Stack, Text, VStack} from "@chakra-ui/react";
import {NavButton} from "../common/NavButton";
import {FiCalendar, FiEdit, FiHome, FiSettings, FiUsers} from "react-icons/fi";
import {HiOutlineDocument} from "react-icons/hi";
import {MdMessage} from "react-icons/all";
import {committeeSidebarProps} from "../common/Props";

const MobileCommitteeSidebar = ({ committeeData, basePath, navActive, navigate }: committeeSidebarProps): JSX.Element => {
  return (
    <Box
      mt='72px'
      bg='bg-surface'
      width='90%'
      alignSelf='center'
      borderRadius='xl'
    >
      <VStack spacing={{ base: '2' }} align='start' py='2'>
        <Stack spacing='1' pb={2} justify='center' width='full'>
          <Heading size='xs' noOfLines={1} alignSelf='center'>{committeeData.name}</Heading>
          {committeeData.isProgram ? <Badge alignSelf='center' colorScheme='blue' variant='solid'>PROGRAM</Badge> : <></>}
        </Stack>
        <Stack spacing='1' width='full' px='2'>
          <NavButton label='Home' icon={FiHome} path={`${basePath}/home`} aria-current={navActive === 'home' ? 'page' : 'false'} navigateFunc={navigate} />
        </Stack>
        <Stack width='full' px='2'>
          <Text fontSize='sm' color='subtle' fontWeight='medium'>
            Members
          </Text>
          <Stack spacing='1'>
            {/*<NavButton label='Documents' icon={HiOutlineDocument} path={`${basePath}/documents`} aria-current={navActive === 'documents' ? 'page' : 'false'} navigateFunc={navigate} />*/}
            {/*<NavButton label='Forms' icon={FiEdit} path={`${basePath}/forms`} aria-current={navActive === 'forms' ? 'page' : 'false'} navigateFunc={navigate} />*/}
            <NavButton label='Calendar' icon={FiCalendar} path={`${basePath}/calendar`} aria-current={navActive === 'calendar' ? 'page' : 'false'} navigateFunc={navigate} />
            <NavButton label='Messaging' icon={MdMessage} path={`${basePath}/messaging`} aria-current={navActive === 'messaging' ? 'page' : 'false'} navigateFunc={navigate} />
          </Stack>
        </Stack>
        <Stack width='full' px='2'>
          <Text fontSize='sm' color='subtle' fontWeight='medium'>
            Committee Administration
          </Text>
          <Stack spacing='1'>
            <NavButton label='Members' icon={FiUsers} path={`${basePath}/roster`} aria-current={navActive === 'members' ? 'page' : 'false'} navigateFunc={navigate} />
            {/*<NavButton label='Settings' icon={FiSettings} path={`${basePath}/admin`} aria-current={navActive === 'settings' ? 'page' : 'false'} navigateFunc={navigate} />*/}
          </Stack>
        </Stack>
      </VStack>
    </Box>
  );
};

export default MobileCommitteeSidebar;