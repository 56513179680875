import React, {useRef, useState} from 'react'
import { Button, Divider, Flex, FormControl, FormLabel, Select, Stack, Textarea } from '@chakra-ui/react'
import DatePicker2 from '../DatePicker/datepicker'

interface HourSubmitFormProps {
  borderColor?: string
}

interface HoursSubmitCategoryData {
  name: string
  value: string
  subcategories: {
    name: string
    value: string
  }[]
}

const data: HoursSubmitCategoryData[] = [
  {
    name: 'Battle of the Badges',
    value: 'battle_of_the_badges',
    subcategories: [
      {
        name: 'Admin',
        value: 'bob_admin',
      },
      {
        name: 'Event Day',
        value: 'bob_event_day',
      },
    ]
  }
]

const HourSubmitForm = (props: HourSubmitFormProps): JSX.Element => {
  const {borderColor} = props

  const [startDatetime, setStartDatetime] = useState<Date | undefined>(new Date())
  const [endDatetime, setEndDatetime] = useState<Date | undefined>(new Date())
  const [category, setCategory] = useState<string | undefined>(undefined)
  const [subcategory, setSubcategory] = useState<string | undefined>(undefined)

  const hasSubcategories = (category: string | undefined) => {
    if (!category) return false;
    const selectedCategory = data.find(item => item.value === category);
    // @ts-ignore
    return selectedCategory?.subcategories?.length > 0;
  }
  return (
    <>
      <Stack spacing='5' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
        <FormControl id='category'>
          <FormLabel>Category</FormLabel>
          <Select borderColor={borderColor ? borderColor : undefined} placeholder='Select option' onChange={(e) => setCategory(e.target.value)}>
            {
              data.map(category => (
                  <option value={category.value}>{category.name}</option>
              ))
            }
            <option value='0'>No matching category</option>
          </Select>
        </FormControl>
        <FormControl id='category' hidden={!hasSubcategories(category)}>
          <FormLabel>Subcategory</FormLabel>
          <Select borderColor={borderColor ? borderColor : undefined} placeholder='Select option' onChange={(e) => setSubcategory(e.target.value)}>
            {
              data.filter(value => (value.value === category))[0]?.subcategories.map(category => (
                  <option value={category.value}>{category.name}</option>
              ))
            }
            <option value='0'>No matching sub-category</option>
          </Select>
        </FormControl>
        <FormControl id='description' isRequired={category === '0'}>
          <FormLabel>Description</FormLabel>
          <Textarea placeholder='Event description' />
        </FormControl>
        <Stack spacing='6' direction={{ base: 'column', md: 'row' }}>
          <FormControl id='startDatetime' zIndex={2}>
            <FormLabel>Start</FormLabel>
            <DatePicker2 onChange={(date) => setStartDatetime(date)} selectedDate={startDatetime}/>
          </FormControl>
          <FormControl id='endDatetime' zIndex={1}>
            <FormLabel>End</FormLabel>
            <DatePicker2 onChange={(date) => setEndDatetime(date)} selectedDate={endDatetime}/>
          </FormControl>
        </Stack>
      </Stack>
      <Divider />
      <Flex direction='row-reverse' py='4' px={{ base: '4', md: '6' }}>
        <Button type='submit' colorScheme='green'>
          Submit Hours
        </Button>
      </Flex>
    </>
  )
}

export default HourSubmitForm
