import React from 'react'
import { Avatar, Button, ButtonGroup, Container, Divider, Flex, Heading, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { navbarData } from '../../../constants/NavbarData'
import { websiteHelpMailto } from '../../../constants/config'
import { FiHelpCircle } from 'react-icons/fi'
import { DesktopNavbarProps } from '../common/props'
import {ColorModeSwitcher} from "../../ColorModeSwitcher/ColorModeSwitcher";

const DesktopNavbar = ({ navbarActive, navigate }: DesktopNavbarProps): JSX.Element => {
  return (
    <>
      <Container>
        <Flex justify='space-between' py={{ base: '3', lg: '4' }}>
          <HStack spacing='4'>
            <Heading size='sm'>ORG</Heading>
            <ButtonGroup variant='ghost' spacing='1'>
              {navbarData.map((item) => (
                <Button key={item.name} aria-current={item === navbarActive ? 'page' : 'false'} onClick={() => { navigate(item.href) }}>{item.name}</Button>
              ))}
            </ButtonGroup>
          </HStack>
          <HStack spacing='4'>
            <ButtonGroup variant='ghost' spacing='1'>
              <ColorModeSwitcher />
              {/*<IconButton as='a' href={websiteHelpMailto} icon={<FiHelpCircle fontSize='1.25rem' />} aria-label='Help' />*/}
            </ButtonGroup>
            <Menu>
              <MenuButton
                as={Button}
                rounded='full'
                variant='link'
                cursor='pointer'
                minW={0}
              >
                <Avatar boxSize='10' name='Christoph Winston' src='https://tinyurl.com/yhkm2ek8' />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => { navigate('/account') }}>Profile</MenuItem>
                <MenuItem onClick={() => { navigate('/account/settings') }}>Settings</MenuItem>
                <MenuDivider borderColor='initial' />
                <MenuItem color='red.400' fontWeight='bold'>Logout</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Container>
      {navbarActive?.subMenuItems !== undefined
        ? <>
          <Divider />
          <Container py='2'>
            <Flex justify='space-between'>
              <ButtonGroup variant='ghost' spacing='1'>
                <>
                  {navbarActive.subMenuItems.map((item) => (
                    <Button
                      key={item.name}
                      aria-current={item.href === window.location.pathname ? 'page' : 'false'}
                      onClick={() => { navigate(item.href) }}
                    >
                      {item.name}
                    </Button>
                  ))}
                </>
              </ButtonGroup>
            </Flex>
          </Container>
        </> // eslint-disable-line
        : <></>}
    </>
  )
}

export default DesktopNavbar
